import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import debounce from 'lodash/debounce'
import "../transaction/imageview.css"
const $ = require("jquery")
$.Datatable = require("datatables.net");
 // Import debounce function from lodash

export default function SuccessPayment() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [data, setData] = useState([]);
  const [hidePhone, setHidePhone] = useState(false);
  const [limit, setLimit] = useState(100);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [utr, setUtr] = useState("");

  const setpageLimit = (event) => {
    setLimit(event.target.value);
  };

  const handleUtrChange = (event) => {
    setUtr(event.target.value);
  };

  // Debounced version of the search function
  const fetchData = debounce(() => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    axios.get(`${baseUrl}User/manualpaymentsuccesslist?page=${pageNumber}&_limit=${limit}&utr=${utr}`, { headers })
      .then((res) => {
        console.log(res.data.admin);
        setData(res.data.admin);
        setNumberOfPages(res.data.totalPages);
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  }, 500); // Debounce delay in milliseconds

  useEffect(() => {
    fetchData();
  }, [pageNumber, limit, utr]); // Fetch data when pageNumber, limit, or utr change

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
  }

  const agentme = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const res = await axios.get(baseUrl + `me`, { headers });
      if (res.data.user_type === "Agent" && res.data.Permissions[31].Status === false) {
        setHidePhone(true);
      }
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    agentme();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className='d-flex align-items-center justify-content-between'>
                <h4 className="card-title">Success Payment</h4>
                <select className='form-control col-sm-1 m-1' id='pagelimit' name='pagelimit' onChange={setpageLimit}>
                  <option value="10">Set limit</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
                <input
                  type="text"
                  className="form-control col-sm-3 m-1"
                  placeholder="Search by UTR number"
                  value={utr}
                  onChange={handleUtrChange}
                />
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>Amount</th>
                      <th>Phone</th>
                      <th>UTR Number</th>
                      <th>UPI ID</th>
                      <th>Status</th>
                      <th>Accepted By</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item._id}</td>
                        <td>{item.amount}</td>
                        <td>{!hidePhone && item.Phone}</td>
                        <td>{item.urtID}</td>
                        <td>{item.upiId}</td>
                        <td>{item.status}</td>
                        <td>{item.Accepetd_By}</td>
                        <td>{dateFormat(item.createdAt).split(',')[0]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='mt-4'>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={(data) => setPageNumber(data.selected + 1)}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
