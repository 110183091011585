import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function UpdatePrize() {
    const history = useHistory();

    // Prize fields (no password fields now)
    const [winnerPrize1, setWinnerPrize1] = useState('');
    const [winnerPrize2, setWinnerPrize2] = useState('');
    const [winnerPrize3, setWinnerPrize3] = useState('');
    const [winnerPrize4, setWinnerPrize4] = useState('');
    const [tournamentDataId, setTournamentDataId] = useState(null); // Store tournament ID

    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

    useEffect(() => {
        // Fetch prize data on component mount
        tournamentGet();
    }, []);

    // Fetching prize data from the backend
    const tournamentGet = async () => {
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        };

        try {
            const response = await axios.get(
                `${baseUrl}tournament-winner-prize-list`, 
                { headers }
            );

            // Assuming response is an array and contains prize data as the first item
            if (response.data && response.data.length > 0) {
                const tournamentData = response.data[0]; // Accessing the first object
                setWinnerPrize1(tournamentData.winnerPrize1 || '');
                setWinnerPrize2(tournamentData.winnerPrize2 || '');
                setWinnerPrize3(tournamentData.winnerPrize3 || '');
                setWinnerPrize4(tournamentData.winnerPrize4 || '');
                setTournamentDataId(tournamentData._id); // Store tournament ID
            }
        } catch (error) {
            console.error('Error fetching prize data:', error);
            alert('An error occurred while fetching the tournament prize data.');
        }
    };

    // Handling form submission to update prize data
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Collecting data to update
        const data = {
            winnerPrize1,
            winnerPrize2,
            winnerPrize3,
            winnerPrize4,
        };

        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        };

       
        if (!tournamentDataId) {
            alert("Tournament ID is missing.");
            return;
        }

        try {
            const response = await axios.post(
                `${baseUrl}tournament-winner-prize-update`, 
                { ...data, tournamentDataId },
                { headers }
            );
        
            if (response.status === 200) {
                alert('Prizes updated successfully!');
            }
        } catch (error) {
            console.error('Error updating prizes:', error);
            alert('An error occurred while updating the prizes.');
        }
    };

    return (
        <div>
            <h4 className='text-uppercase font-weight-bold my-3'>Update Tournament Prizes</h4>
            <form id="update_prizes_form" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="winnerPrize1" className='text-dark'>Winner Prize 1</label>
                        <input
                            type="number"
                            className="form-control"
                            id="winnerPrize1"
                            name="winnerPrize1"
                            placeholder="Winner Prize 1"
                            onChange={(e) => setWinnerPrize1(e.target.value)}
                            value={winnerPrize1}
                            required
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="winnerPrize2" className='text-dark'>Winner Prize 2</label>
                        <input
                            type="number"
                            className="form-control"
                            id="winnerPrize2"
                            name="winnerPrize2"
                            placeholder="Winner Prize 2"
                            onChange={(e) => setWinnerPrize2(e.target.value)}
                            value={winnerPrize2}
                            required
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="winnerPrize3" className='text-dark'>Winner Prize 3</label>
                        <input
                            type="number"
                            className="form-control"
                            id="winnerPrize3"
                            name="winnerPrize3"
                            placeholder="Winner Prize 3"
                            onChange={(e) => setWinnerPrize3(e.target.value)}
                            value={winnerPrize3}
                            required
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="winnerPrize4" className='text-dark'>Winner Prize 4</label>
                        <input
                            type="number"
                            className="form-control"
                            id="winnerPrize4"
                            name="winnerPrize4"
                            placeholder="Winner Prize 4"
                            onChange={(e) => setWinnerPrize4(e.target.value)}
                            value={winnerPrize4}
                            required
                        />
                    </div>
                </div>

                <div>
                    <button type="submit" className='btn btn-success'>Update</button>
                </div>
            </form>
        </div>
    );
}
