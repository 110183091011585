import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";

const $ = require("jquery")
$.Datatable = require("datatables.net");

function Banner() {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    // const path = location.pathname.split("/")[2];
    if (nodeMode === "development") {
        var baseUrl = beckendLocalApiUrl;
    } else {
        var baseUrl = beckendLiveApiUrl
    }
    const access_token = localStorage.getItem("token")
    //use for pagination..
    let [limit, setLimit] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const setpageLimit = (event) => {
        let key = event.target.value
        setLimit(key);
    }

    const [EARING, setEARING] = useState()

    const [values, setValues] = useState([
        new DateObject().subtract(4, "days"),
        new DateObject().add(4, "days")
    ])

    //react paginate..
    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        setPageNumber(currentPage);
        // scroll to the top
        //window.scrollTo(0, 0)
    };

    useEffect(() => {

        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.get(`https://kycjvt.rushkingludo.com/admin-banner-list?page=${pageNumber}&_limit=${limit}`, { headers })
            .then((res) => {
                setEARING(res.data)
                setNumberOfPages(res.data.totalPages)
            })
    }, [pageNumber, limit, values]);


    const updateBanner = (id, status) => {
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };

        const updatedStatus = status === "active" ? "inactive" : "active";

        axios
            .post(
                `https://kycjvt.rushkingludo.com/admin-banner-update`,
                { id, status: updatedStatus },
                { headers }
            )
            .then((res) => {
                const reversedData = res.data.reverse();
                setEARING(reversedData);
            })
            .catch((err) => {
                console.error("Error updating banner:", err);
                alert("Failed to update the banner. Please try again.");
            });
    };



    const deleteBanner = (id) => {
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };
    
        axios
            .post(
                `https://kycjvt.rushkingludo.com/admin-banner-delete/${id}`,
                { id },
                { headers }
            )
            .then((res) => {
                // All();  
                setEARING(res.data);  
                // window.location.reload();
            })
            .catch((err) => {
                console.error("Error deleting banner:", err);
                alert("Failed to delete the banner. Please try again.");
            });
    };
    

    const handleExport = () => {
        let table1 = [
            {
                A: "id",
                B: "Amount"
                // F:"Bonus by (ID)"
            },
        ];

        EARING.forEach((row) => {
            const Earing = row;
            //console.log("exldata", Earing);
            table1.push({
                A: `Earn From Challange ${Earing.Earned_Form} on ${Earing.createdAt}`,
                B: Earing.Ammount,
                // F:(userDetails.action_by)?userDetails.action_by:'N/A'
            });
        });

        //table1 = [{A:"User Details"}].concat(table1);
        //const finalData = [...table1];
        //console.log(finalData);
        /* convert state to workbook */
        const ws = XLSX.utils.json_to_sheet(table1, {
            skipHeader: true,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, "AdminEarning.xlsx");
    };

    return (
        <>
            <div className="mt-3 d-flex justify-content-between align-items-baseline">
                <h3 className='d-flex flex-row text-light'>
                    <div class="alert alert-primary" role="alert">
                        BANNER
                    </div>
                </h3>
                <select className='form-control col-sm-1 m-1 bg-dark text-light' id='pagelimit' name='pagelimit' onChange={setpageLimit}>
                    <option value="10">Set limit</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                </select>
                <div>


                    {/* <button
            onClick={() => {
              // createDownloadData();
            }}
            className="btn btn-primary p-2"
          >
            Add Data
          </button> */}
                    <Link type='button' className="btn btn-primary p-2" to="/addbanner">Add Banner</Link>
                </div>
            </div>


            <div className="row ">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body text-light">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Media</th>
                                            {/* <th>Type</th> */}
                                            {/* <th>Title</th> */}
                                            {/* <th>Description</th> */}
                                            <th>Status</th>
                                            <th>Update</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {EARING && EARING.map((Earing, index) => (
                                            <tr key={Earing._id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <div className="d-flex">
                                                        <img src={`https://kycjvt.rushkingludo.com//${Earing.media}`} style={{ borderRadius: '5px', width: '100%', height: 'auto' }} alt="Media" className="media-thumbnail" />
                                                    </div>
                                                </td>
                                                <td className="text-capitalize" style={{ color: Earing.status === "active" ? "green" : "red" }}>{Earing.status}

                                                </td>
                                                <td>
                                                <input
                                                        type="checkbox"
                                                        className="toggle-switch"
                                                        checked={Earing.status === "active"}
                                                        onChange={() => updateBanner(Earing._id, Earing.status)}
                                                    />
                                                </td>
                                                <td>
                                                    <button type='button' className="btn btn-danger p-2 " onClick={() => {
                                                        deleteBanner(Earing._id);
                                                    }}>Delete</button>
                                                    {/* 
                                                    <button type='button' className="btn btn-primary p-2 ml-2" onClick={() => {
                                                        updateBanner(Earing._id, Earing.status);
                                                    }}>Update</button> */}


                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                            <div className='mt-4'>
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={numberOfPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner